<template>
  <div>
    <img
      src="../assets/img/goback1.png"
      alt=""
      @click="onClickLeft"
      class="goback"
    />
    <div class="loginBox">
      <div class="accountTitle">
        <img src="../assets/img/image.png" alt="" />
        <ul>
          <li class="fontTitle">快乐星球</li>
          <li class="englishTitle">HAPPY STAR</li>
        </ul>
      </div>
      <p class="tableTitle">Forgot</p>
      <p class="tableTitle">Password</p>
      <van-form @submit="onBlur">
        <van-cell-group inset class="cellTable">
          <van-cell value="Email" />
          <van-field
            v-model="tableFrom.account"
            label=""
            clearable
            border="true"
            maxlength="30"
            left-icon="envelop-o"
          />
          <van-cell value="Verification code" />
          <p class="codeNum">
            <van-field
              v-model="tableFrom.code"
              center
              clearable
              label=""
              class="verification"
              left-icon="shield-o"
              maxlength="20"
            />
            <van-button
              size="small"
              type="primary"
              @click="getEmail"
              class="sendOut"
              >{{ numState }}</van-button
            >
            <van-button
              size="small"
              type="primary"
              class="countDown"
              v-if="isShow"
              >{{ numDown }}</van-button
            >
          </p>
        </van-cell-group>
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          class="loginButton"
          @click="loginLine"
        >
          NEXT
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import "vant/lib/button/style";
import { Toast } from "vant";
import { reactive, toRefs, onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import service from "@/utils/api";
import method from "../utils/codeJudgment";

export default {
  name: "changePassword",
  props: {},
  setup() {
    const router = useRouter();
    // 输入验证
    const state = reactive({
      tableFrom: [],
      checked: "",
      // Remember password
      remember: "",
      // 倒计时
      numState: "GET",
      numDown: "",
      timer: null,
      isShow: false,
    });
    // 下一步
    const loginLine = () => {
      // 判断邮箱是否已经输入
      if (!state.tableFrom.account) {
        Toast.fail("Please enter email address");
        return false;
      }
      if (!state.tableFrom.code) {
        Toast.fail("Please enter the verification code");
        return false;
      }
      const params = {
        account: state.tableFrom.account,
        code: state.tableFrom.code,
      };
      service.getVerifypwdcode(params).then((res) => {
        if (res.data.errcode == 0) {
          console.log(res.data);
          state.data = res.data.data;
          router.push({
            path: "/resetPassword",
            query: {
              data: state.data,
              account: state.tableFrom.account,
            },
          });
        } else {
          Toast.fail(method.getTextByStatus(res.data.errcode));
        }
      });
    };

    // 获取验证码
    const getEmail = () => {
      // 判断邮箱是否已经输入
      if (!state.tableFrom.account) {
        Toast.fail("Please enter email address");
        return false;
      }
      getCodeTime();
      // 调用接口，发送短信验证码
      service.getPwdcode({ account: state.tableFrom.account }).then((res) => {
        if (res.data.errcode != 0) {
          Toast.fail(method.getTextByStatus(res.data.errcode));
        } else {
          state.isShow = true;
        }
      });
    };
    // 验证码倒计时
    const getCodeTime = () => {
      const TIME_COUNT = 120;
      if (!state.timer) {
        state.numDown = TIME_COUNT;
        state.timer = setInterval(() => {
          if (state.numDown > 1 && state.numDown <= TIME_COUNT) {
            state.numDown--;
          } else {
            clearInterval(state.timer);
            state.timer = null;
            state.isShow = false;
            state.numState = "Send again";
          }
        }, 1000);
      }
    };
    // 返回
    const onClickLeft = () => {
      router.push({ path: "/loginSecond" });
    };

    onMounted(() => {});

    return {
      ...toRefs(state),
      loginLine,
      onClickLeft,
      getEmail,
      getCodeTime,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  .goback {
    position: absolute;
    background-color: transparent;
    top: 6%;
    left: 7%;
    width: 35px;
    z-index: 1;
  }
  // 整体表单
  .loginBox {
    background-color: transparent;
    z-index: 2;
    position: absolute;
    width: 37%;
    height: 100%;
    overflow: hidden;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 5% 30px 13.8% 0px;
    color: #ffffff;

    // 快乐星球盒子
    .accountTitle {
      height: 12%;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        width: 40px;
        height: 100%;
      }
      .englishTitle {
        font-size: 10px;
      }
    }
    // Forgot Password
    .tableTitle {
      text-align: left;
      font-size: 20px;
    }
    // 表单背景色
    .cellTable {
      background-color: transparent !important;
      .van-cell {
        font-size: 10px;
        overflow: hidden;
        padding-left: 6px;
      }
      // 输入表单的背景色
      .van-field {
        background-image: url("../assets/img/password.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 34px;
        line-height: 34px;
      }
      .codeNum {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        // 验证码框
        .verification {
          width: 74% !important;
        }
        //发送按钮
        .sendOut {
          background-color: transparent !important;
          border: none;
          background-image: url("../assets/img/blueframe.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 25%;
          position: absolute;
          right: 0px;
        }
        // 倒计时按钮
        .countDown {
          background-color: #1a1a1a !important;
          border: 2px solid #666666;
          width: 25%;
          color: #666666;
          position: absolute;
          right: 0px;
        }
      }
    }

    // 登录按钮
    .loginButton {
      width: 100%;
      height: 40px;
      background-color: transparent;
      background-image: url("../assets/img/account.png");
      background-size: 100%;
      background-repeat: no-repeat;
      border: none;
      margin: 14px 0px;
    }
  }
}
</style>
